import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../button'
import { Success } from '../images'

interface iCompleted {
    source?: string,
    activechallenge? : string,
}

const CompletedEvent = ({ source = "challenge", activechallenge = "" }: iCompleted) => {
    const navigate = useNavigate()
    const [challenge, setChallenge] = useState("")
    const { pathname, search } = useLocation();

    useEffect(() => {
        const search_ = (search.replace('?', '')).split("&")
        if (search_.length > 0) {
            search_.forEach(s => {
                if (s.includes('challenge=') !== false) {
                    const cha = s.replace('challenge=', '')
                    setChallenge(cha)
                }
            })
        }
    }, [])
    // const a = []
    return (
        <div>
            <div className='back_drop fixed top-0 left-0 w-full z-[999999] bg-[#F0F0F4] h-[100vh] opacity-80'>

            </div>
            <div className='fixed top-0 left-0 w-full z-[9999999] h-[100vh]'>
                <div className="h-[100vh] w-full flex items-center justify-center">
                    <div className='bg-white shadow-lg rounded-[20px] w-[95%] lg:w-[460px] p-3 relative'>
                        <div className='flex justify-center w-[95%] lg:w-[460px] absolute top-[-50px] lg:top-[-80px]'>
                            <img src={Success} className="h-[100px] lg:h-[152px] object-contain" alt="" />
                        </div>

                        <div className="mt-[60px] lg:mt-[80px] text-center">
                            <h3 className="text-blue text-[20px] font-primary font-bold">
                                {
                                    source == "sub" ?
                                        "Subscription Added Successfully"
                                        :
                                        "Event successfully created"
                                }
                            </h3>
                            <p className='text-blue max-w-[280px] mx-auto text-[14px] opacity-80'>
                                {
                                    source == "sub" ?
                                        "Thank you for using Beentos"
                                        :
                                        "Turn on your notifications to see how your Event is doing"
                                }
                            </p>
                        </div>
                        <div className="my-[30px] h-[1px] bg-[#7E799F80] w-full"></div>
                        <div className="w-full mb-[20px] text-center">
                            <Button text="Continue" onclick={() => {
                                if (window.location.href.includes("admin")) {
                                    navigate('/admin/campaign-management')
                                } else {
                                    if (source == "sub") {
                                        if (challenge !== "") {
                                            navigate(`/brand/challenge/create-step-check?challenge=${challenge}`)
                                            return false;
                                        }
                                    }
                                    navigate('/brand/dashboard')
                                }
                            }} containerClass="w-[80%] bg-blue text-yellow mt-[0px]" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompletedEvent