import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AiOutlinePlusCircle } from "react-icons/ai";
import { needHelp } from '../../components/images';
import useDocumentTitle from '../../helpers/pageTitle';
import { decryptData } from '../../helpers/security';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import { BiDotsVertical } from 'react-icons/bi';
import Button from '../../components/button';
import ChallengeCard from '../../components/brand/challengeCard';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import Pagination from '../../components/pagination';
import ScrollToTop from '../../helpers/scrollToTop';
import Loader from '../../helpers/loader';
// import { ShareModal } from '../../components/brand';
import ls from 'localstorage-slim';
import { getUserLocation } from '../../helpers/helpers';
import EventCard from '../../components/brand/event_card';

const Dashboard: React.FC = () => {
    const [userId, setUserid] = useState("")
    const { loggedInBrand, loggedIn } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { search } = useLocation()
    const [user, setUser] = useState('')
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const [challenges, setChallenges] = useState([])
    const [brand, setBrand] = useState<any>({})
    const [isLoading, setIsLoading] = useState(true)
    const [page, setPage] = useState(1)
    const headers = {
        'Authorization': `Bearer ${loggedInBrand}`
    }

    useEffect(() => {
        //set user location info
        getUserLocation()
            .then((userLocation) => {
                ls.set('blocation', userLocation, { encrypt: true })
                instance({
                    method: "GET",
                    url: `payment/rate/${userLocation[3]}`,
                    headers: headers
                }).then((response) => {
                    const usrate = response.data.data.rate;
                    ls.set('busrate', usrate, { encrypt: true })

                }).catch((err) => {
                    console.log(err)
                })
            })
            .catch((err) => console.log("Error storing user location", err.message))

        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "brand"
        })

    }, [])
    useEffect(() => {
        if (!loggedInBrand || loggedInBrand == "") {
            // nobody is logged in
            navigate("/brand/login");
        }

    }, [loggedInBrand])

    useEffect(() => {
        const search_ = (search.replace('?', '')).split("&")
        if (search_.length > 0) {
            search_.forEach(s => {
                if (s.includes('page=') !== false) {
                    const scrollTo = s.replace('page=', '')
                    setPage(parseInt(scrollTo))
                    // ScrollToTop()
                    setIsLoading(true)
                    window.scrollTo(0, 0);
                    setTimeout(() => {
                        setIsLoading(false)
                        return false;
                    }, 500);
                }
            });
        }
    }, [search])

    const [allRecords, setAllRecords] = useState(0)
    
    useEffect(() => {
        instance({
            method: "GET",
            url: `/user/auth`,
            headers: headers
        }).then((response) => {
            setUserid(response.data.data._id)
        }).catch((err) => {
        }).finally(() => {
        })
    }, [])

    // get brand profile
    useEffect(() => {
       
        instance({
            method: "GET",
            url: "/brand/profile",
            headers: headers
        }).then((response) => {
            // console.log("brand ", response.data)
            var brandid = response.data.data.brand.brandId

            ls.set("app_brand", brandid, { encrypt: true })
            instance({
                method: "GET",
                url: `/challenge/all?brandId=${brandid}&published=true`,
                headers: headers
            }).then((response) => {
                // console.log(response.data)
                setAllRecords(response.data.data.totalDocs)
                const c = response.data.data.docs;
                if (c == null) setChallenges([])
                else if (c.length === 0) setChallenges([])
                else setChallenges(c)
                setIsLoading(false)
            })
            var brand = response.data.data.brand;
            // console.log(brand._id)
            setBrand(response.data.data.brand);
        }).catch((err) => {
            if (err.code == "ERR_NETWORK") {
                alert("No internet connection")
            }
            if (err.response.data.statusCode === 403 || err.response.data.statusCode === 401) {
                navigate('/brand/login');
                return false;
            }
            toast.error(err.response.data.message)
            console.log(err)
        }).finally(()=> {
            setIsLoading(false)

        })
    }, [])

    // get brand profile
    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInBrand}`
        }
        instance({
            method: "GET",
            url: "/subscription/brand/subscription",
            headers: headers
        }).then((response) => {
            console.log(response)
            var data = response.data.data;
            ls.set("bsub", data, { encrypt: true });
        }).catch((err) => {
            ls.set("bsub", null, { encrypt: true });
        })
    }, [])


    useDocumentTitle('BRAND DASHBOARD')
    // const a = []
    return (
        <div>
            <div className="max-w-[1445px]">
                <ToastContainer />
                {/* wrapper */}
                {
                    isLoading ? <Loader /> :
                        <div className="flex">
                            {/* main */}
                            <div className='container font-primary min-h-[95vh] flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                                <div className="mb-3">
                                    <h3 className='font-bold '>Welcome: {brand?.brandName}</h3>
                                </div>
                                <div className="flex">
                                    <a href="#?" className='text-blue text-sm font-bold border-b-2 border-sky-950 mr-[10px]'>Active</a>
                                    <Link to="/brand/challenges/pending" className='text-[#7E799F] text-sm font-bold mr-[10px]'>Pending</Link>
                                    <Link to={'/brand/challenges/draft'} className='text-[#7E799F] text-sm font-bold'>Draft</Link>
                                </div>

                                {
                                    challenges.length == 0 ?
                                        <div className='text-center flex flex-col items-center mt-[25vh]'>
                                            <h3 className='text-[30px] font-primarybold text-blue text-center'>Looks like it’s your first time here</h3>
                                            <p className='text-[#7E799F80]'>Click on the  button below to create your first challenge</p>
                                            <div className="mt-[10px]">
                                                <Link to={'/brand/challenge/create'} className='bg-yellow rounded-[45px] font-bold font-primary text-blue h-[35px] flex items-center justify-center px-[10px] text-[14px]'> <AiOutlinePlusCircle /> <div className="hidden lg:block mx-1"></div> <span className=''>Create a Campaign</span> </Link>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div className="mt-4">
                                                {
                                                    (challenges.slice(page == 1 ? 0 : (page - 1) * 5, page == 1 ? 5 : ((page - 1) * 5) + 5)).map((challenge, i) => (
                                                        <EventCard key={i} data={challenge} userId={userId} />
                                                    ))
                                                }

                                            </div>
                                            <div className="mt-3">
                                                <Pagination limit={5} length={allRecords} page={page} />
                                            </div>

                                        </div>
                                }

                            </div>
                            {/* sidebar */}
                            {/* <aside className={`h-100 min-h-[90vh] z-40 px-[10px] lg:static lg:block py-[20px] ${acctSideBarActive ? 'absolute left-0 top:0' : 'hidden'} flex-initial w-full max-w-[320px] bg-white shadow`}>
                                <div className="table-fixed w-full pr-3 min-h-[50vh]">
                                    <div className='flex mb-3'>
                                        <p className='w-[75%] font-primary text-[#7E799F] font=bold ' >Challenges</p>
                                        <p className='font-primary text-[#7E799F] font=bold ' >Participants</p>

                                    </div>
                                    <div>
                                        {
                                            (challenges.slice(0, 10)).map((item: any, i) => (
                                                <Link key={i} to={`/brand/challenge/result/${item._id}`} className='flex items-center py-[5px]'>
                                                    <div className='w-[70%] text-sm text-blue font-primary'>{item.title}</div>
                                                    <div className='text-center w-[25%]'> <button className='rounded-[15px] text-[12px] text-[#0B0149] p-2 bg-[#F0F0F4]'>{(item.challengePosts).length}</button> </div>
                                                </Link>
                                            ))
                                        }
                                    </div>
                                </div>

                                <div className="h-[1px] my-[20px] bg-[#7E799F] opacity-70 w-full"></div>
                                <div className='text-center w-full flex justify-center'>
                                    <a href="mailto:support@beentos.com">
                                    <img src={needHelp} alt="" />
                                    </a>
                                </div>
                            </aside> */}
                        </div>
                }
            </div>
        </div>
    )
}

export default Dashboard