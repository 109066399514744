import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AiOutlinePlusCircle } from "react-icons/ai";
import { needHelp } from '../../components/images';
import useDocumentTitle from '../../helpers/pageTitle';
import { decryptData } from '../../helpers/security';
import { useTypedSelector } from '../../hooks/useSelector';
import { ActionType } from '../../store/actions';
import { BiDotsVertical } from 'react-icons/bi';
import Button from '../../components/button';
import ChallengeCard from '../../components/brand/challengeCard';
import instance from '../../helpers/axios';
import { toast, ToastContainer } from 'react-toastify';
import Pagination from '../../components/pagination';
import ScrollToTop from '../../helpers/scrollToTop';
import Loader from '../../helpers/loader';
import ConfirmModal from '../../components/brand/ask_modal';
import EventCard from '../../components/brand/event_card';
// import { ShareModal } from '../../components/brand';

const Events: React.FC = () => {
    const { loggedInBrand } = useTypedSelector((state) => state.appReducer);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { search } = useLocation()
    const [user, setUser] = useState('')
    const { acctSideBarActive } = useTypedSelector((state) => state.appReducer);
    const [challenges, setChallenges] = useState([])
    const [Events, setEvents] = useState([])
    const [brand, setBrand] = useState<any>({})
    const [isLoading, setIsLoading] = useState(true)
    const [offset, setOffset] = useState(0)
    const [page, setPage] = useState(1)
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "brand"
        })
    }, [])


    useEffect(() => {
        if (!loggedInBrand || loggedInBrand == "") {
            // nobody is logged in
            navigate("/brand/login");
        }
    }, [loggedInBrand])

    useEffect(() => {
        const search_ = (search.replace('?', '')).split("&")
        if (search_.length > 0) {
            search_.forEach(s => {
                if (s.includes('page=') !== false) {
                    const scrollTo = s.replace('page=', '')
                    setPage(parseInt(scrollTo))
                    // ScrollToTop()
                    setIsLoading(true)
                    window.scrollTo(0, 0);
                    setTimeout(() => {
                        setIsLoading(false)
                        return false;
                    }, 500);
                }
            });
        }
    }, [search])


    // get brand profile
    useEffect(() => {
        const headers = {
            'Authorization': `Bearer ${loggedInBrand}`
        }
        instance({
            method: "GET",
            url: "/brand/profile",
            headers: headers
        }).then((response) => {
            var brandid = response.data.data.brand.brandId
            setBrand(response.data.data.brand);
            instance({
                method: "GET",
                url: `/event`,
                headers: headers
            }).then((response) => {
                console.log("event", response.data)
                const c = response.data.data.docs;
                if (c == null) setEvents([])
                else if (c.length === 0) setEvents([])
                else setEvents(c)
                setIsLoading(false)
            })
        }).catch((err) => {
            if (err.code == "ERR_NETWORK") {
                alert("No internet connection")
            }
            if (err.response.data.statusCode === 403) {
                navigate('/brand/login');
                return false;
            }
        })
    }, [])



    const [showDelete, setShowDelete] = useState(false)
    const [deleteId, setDeleteId] = useState("")
    const deleteChallenge = () => {
        setIsLoading(true)
        // get brand profile
        const headers = {
            'Authorization': `Bearer ${loggedInBrand}`
        }
        instance({
            method: "DELETE",
            url: `/challenge/delete-Eventss`,
            data: {
                "challengeIds": [deleteId]
            },
            headers: headers
        }).then((response) => {
            window.location.reload()
        }).catch((err) => {
            if (err.code == "ERR_NETWORK") {
                alert("No internet connection")
            }
            if (err.response.data.statusCode === 403) {
                navigate('/brand/login');
                return false;
            }

            toast.error(err.response.data.message)
            console.log(err)
        })
    }
    // /api/challenge/brand/challenges/:creationStatus/:offset/:limit
    useDocumentTitle('BRAND Events')
    // const a = []
    return (
        <div>
            <div className="max-w-[1445px]">
                <ToastContainer />
                {
                    showDelete ?
                        <ConfirmModal
                            callback={deleteChallenge}
                            close={() => {
                                setShowDelete(false)
                            }} title='Are you sure?' description='' />
                        : ""
                }
                {/* wrapper */}
                {
                    isLoading ? <Loader /> :
                        <div className="flex">
                            {/* main */}
                            <div className='container font-primary min-h-[95vh] flex-inital lg:px-[15px] px-[5px] py-[25px] w-full'>
                                <div className="flex">
                                    <Link to="/brand/events/" className='text-blue text-sm font-bold border-b-2 border-sky-950 mr-[10px]'>Active</Link>
                                    <Link to="/brand/events/create" className='text-blue text-sm font-bold mr-[10px]'>Create New</Link>
                                </div>

                                {
                                    Events.length == 0 ?
                                        <div className='text-center flex flex-col items-center mt-[25vh]'>
                                            <h3 className='text-[30px] font-primarybold text-blue text-center'>Looks like you have not created any event?</h3>
                                            <p className='text-[#7E799F80]'>Click on the  button below to create your first event</p>
                                            <div className="mt-[10px]">
                                                <Link to={'/brand/events/create'} className='bg-yellow rounded-[45px] font-bold font-primary text-blue h-[35px] flex items-center justify-center px-[10px] text-[14px]'> <AiOutlinePlusCircle /> <div className="hidden lg:block mx-1"></div> <span className=''>Create Event</span> </Link>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div className="mt-4 grid grid-cols-2 gap-4">
                                                {
                                                    (Events.slice(page == 1 ? 0 : (page - 1) * 10, page == 1 ? 10 : ((page - 1) * 10) + 10)).map((challenge: any, i) => (
                                                        <EventCard key={i} data={challenge}  />
                                                    ))
                                                }

                                            </div>
                                            <div className="mt-3">
                                                <Pagination limit={10} length={Events.length} page={page} />
                                            </div>

                                        </div>
                                }

                            </div>
                            {/* sidebar */}

                        </div>
                }

            </div>
        </div>
    )
}

export default Events