import React from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiCog, BiLogOut } from "react-icons/bi";
import { BsApp, BsDiagram2Fill, BsGridFill } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import { useTypedSelector } from "../../hooks/useSelector";
import { sidebarItems } from "../../models/model";
import { Logo, sidebarBg } from "../images";

const BrandAside = () => {
  const path = useLocation().pathname;
  const items: sidebarItems[] = [
    { title: "My Campaigns", icon: <BsGridFill />, link: "/brand/dashboard" },
    // { title: "My Events", icon: <BsDiagram2Fill />, link: "/brand/events" },
    { title: "Analytics", icon: <BsGridFill />, link: "/brand/analytics" },
    // {title : 'Editor', icon : <MdOndemandVideo />, link : "/brand/editor"},
    // {title : 'Editor', icon : <MdOndemandVideo />, link : editor_url},
    {title : 'Apps', icon : <BsApp />, link : '/brand/settings/apps'},

    { title: "Settings", icon: <BiCog />, link: "/brand/settings" },
    // {title : 'Verify Post', icon : <BiUpArrow />, link : '/verify-post'},
    { title: "Log out", icon: <BiLogOut />, link: "/brand/logout" },
  ];
  const { sideBarActive, loggedInBrand } = useTypedSelector(
    (state) => state.appReducer
  );

  return (
    <aside
      className={`font-primary ${
        sideBarActive ? "fixed z-[51211] left-0 top-0 h-[100vh]" : "hidden"
      } lg:flex lg:relative shadow-lg lg:min-h-[90vh] bg-white w-[85%] lg:p-3 lg:pt-[15px] lg:w-[190px] `}
    >
      <div className="lg:hidden">
        <Link
          to="/brand/dashboard"
          className="brand flex justify-center items-center bg-yellow w-full rounded-t-[10px] h-[93px]"
        >
          <img
            src={Logo}
            className="object-contain h-[83px] lg:h-[79px]"
            alt=""
          />
        </Link>
      </div>

      <div className="p-3 lg:p-0 pt-[15px] lg:pt-0">
        {items.map((item, i) => {
          const isActive = item.link === path;
          return item.title.toLowerCase() === "editor" ? (
            <Link
              to={item.link}
              key={i}
              className={`w-full flex items-center  justify-start my-[15px] p-2 ${
                isActive
                  ? "bg-blue text-yellow rounded "
                  : "text-blue opacity-75 hover:opacity-100"
              }`}
            >
              <div className="text-[20px]">{item.icon}</div>
              <div className="ml-1 text-[13px] ">{item.title} &nbsp;</div>
              <span className="bg-blue px-2 text-yellow rounded-[10px] text-[10px] top-[22%] right-[-20px] ">
                beta
              </span>
            </Link>
          ) : (
            <Link
              target={item.title.toLowerCase() == "verify post" ? "" : ""}
              to={item.link}
              key={i}
              className={`w-full flex items-center  justify-start my-[15px] p-2 ${
                isActive
                  ? "bg-blue text-yellow rounded "
                  : "text-blue opacity-75 hover:opacity-100"
              }`}
            >
              <div className="text-[20px]">{item.icon}</div>
              <div className="ml-1 text-[13px] ">{item.title}</div>
            </Link>
          );
        })}
      </div>
      <div className="absolute left-0 bottom-0 w-full">
        <Link
          to={"/brand/challenge/create"}
          className="block lg:hidden bg-blue rounded-[10px] font-bold font-primary text-yellow h-[45px] mb-3 w-[95%] mx-auto flex items-center justify-center px-[10px] text-[14px]"
        >
          {" "}
          <AiOutlinePlusCircle /> <div className="hidden lg:block mx-2"></div>{" "}
          <span className="">Create a Campaign</span>{" "}
        </Link>
        <img src={sidebarBg} className="w-full h-[100px]" alt="" />
      </div>
    </aside>
  );
};

export default BrandAside;
