import React, { useEffect, useState } from 'react'
import { BiDotsVertical, BiWallet } from 'react-icons/bi'
import { Link, useNavigate } from 'react-router-dom';
import { budgetIcon, challengeTypeIcon, musicIcon, needHelp, videoLength, winnersIcon } from '../../components/images';
import { capitalizeFirstLetter, formatDate, position, sharePrize, slugify, ValidDate } from '../../helpers/helpers';
import Button from '../button';
import ShareModal from './share_modal';
import { BsCodeSlash, BsEye } from 'react-icons/bs';
import { Hr } from '..';
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import { Qr } from '../QrContainer';
import IntegrateModal from './integrate_modal';
import { FiCode, FiCopy } from 'react-icons/fi';
import { ToastContainer, toast } from 'react-toastify';
import DownloadQR from './downloadqr';

interface iChallenge {
    data: any,
    source?: string,
    userId?: any
}
const EventCard: React.FC<iChallenge> = ({ data, source = "main", userId=""}) => {
    const [showOptions, setShowOptions] = useState(false)
    const [showIntegration, setShowIntegration] = useState(false)
    const [showDownloadQr, setShowDownloadQR] = useState(false)
    // console.log(data.title)
    const navigate = useNavigate();
    const { title, challengeCode, brand, challengeVisibility, prizeNarration, currency, totalPrizeBudget, noOfWinners, description, challengeType, closingDate, backgroundMusic, _id } = data;
    // const challengeCode = _id
    const brandName = brand ? brand.brandName : ""
    const brandId = brand ? brand.brandId : ""
    
    const shares = sharePrize(totalPrizeBudget, noOfWinners, 3)
    var remaining = Number(totalPrizeBudget - shares.reduce(function (a, b) { return a + b; }, 0)).toFixed(2);
    const ref = React.useRef<HTMLInputElement>(null);
    const [copied, setCopied] = useState(true)
    const [toCopy, SettoCopy] = useState('')

    const downloadCanvas = (id: string) => {
        var input = document.getElementById(id);
        html2canvas(input!)
            .then((canvas) => {
                var imgData = canvas.toDataURL('image/jpg');
                console.log(imgData)
            })
    }
    const clickToCopy = (content: string) => {
        if (content == "link") {
            SettoCopy(`https://${window.location.host}/e/${slugify(brandName)}/${challengeCode}`)
        }
    }
    useEffect(() => {
        if (toCopy === "") return;
        else {
            if (ref.current) {
                ref.current?.select();
                try {
                    document.execCommand('copy');
                    ref.current?.blur();
                    SettoCopy("")
                    toast.success("Copied to clipboard")
                } catch (err) {
                    alert('please press Ctrl/Cmd+C to copy');
                }
            }
        }
    }, [toCopy])
    return (
        <div className={`bg-white p-3 ${source == 'result' ? 'mt-4 rounded-t-[15px]' : 'my-4 rounded-[15px]'} relative lg:p-[20px]`}>
            <ToastContainer />
            {
                toCopy === "" ? '' :
                    <input className='absolute' ref={ref} onChange={() => { }} type="text" value={toCopy} />
            }
            {
                showIntegration &&
                <IntegrateModal close={() => {
                    setShowIntegration(false)
                }} challenge={data} />
            }
            {showDownloadQr && <DownloadQR brandName={brandName} challengeCode={challengeCode} close={()=> {
                setShowDownloadQR(false)
            }} />
                }
            {
                showOptions ?
                    <div className="rounded-[10px] z-[4444] absolute top-[40px] right-[10px] text-blue font-primary bg-white p-3 shadow-blue shadow-md">
                        <div className='my-2'>
                            <a onClick={() => {
                                setShowDownloadQR(true)
                                setShowOptions(false)
                            } 
                            } href="#?" className='my-'>Download QR Code</a>
                        </div>
                        <div className='my-2'>
                            <Link to={'/brand/challenge/edit?challenge=' + data._id} className='my-2'>Edit Event</Link>
                        </div>
                        {
                            challengeCode &&
                            <div className='my-2'>
                                <Link to={'#?'} onClick={() => {
                                    setShowOptions(false)
                                    clickToCopy("link")
                                    // setShowIntegration(true)
                                    // setShowOptions(false)

                                }} className='my-2 flex items-center gap-2'>Copy link</Link>
                            </div>
                        }
                    </div>
                    : ''
            }

            <div className="header flex justify-between items-center" >
                <h3 className='text-blue font-bold font-primary' onClick={() => setShowOptions(false)}>{title}</h3>
                {source == "main" || source == "pending" ?
                    <BiDotsVertical onClick={() => setShowOptions(!showOptions)} />
                    : ""}
            </div>
            <div className="sub_header mt-3 bg-graylight rounded-[10px] py-3" onClick={() => setShowOptions(false)}>
                <div className="grid grid-cols-2 gap-y-3 lg:grid-cols-2">
                    <div className="col text-center">
                        <div className="px-3 flex items-center justify-center font-primary text-blue opacity-75">
                            <img src={videoLength} alt="" className='h-[14px] object-contain' /> &nbsp;
                            <p className='text-[14px]'>Items Submitted</p>

                        </div>
                        <div className="h-[1px] my-2 bg-[#7E799F80] w-full"></div>
                        <h3 className="text-blue font-bold font-primary">3</h3>
                    </div>
                    <div className="col text-center">
                        <div className="px-3 flex items-center justify-center font-primary text-blue opacity-75">
                            <img src={winnersIcon} alt="" className='h-[14px] object-contain' /> &nbsp;
                            <p className='text-[14px]'>Items per slide</p>
                        </div>
                        <div className="h-[1px] my-2 bg-[#7E799F80] w-full"></div>
                        <h3 className="text-blue font-bold font-primary">{3}</h3>
                    </div>
                   
                </div>
            </div>
            {source == "main" || source == "pending" ?
                <>
                    <div className="flex" onClick={() => setShowOptions(false)}>
                        <div className="flex-inial lg:w-[70%] w-full">
                            <div className="mt-4">
                                <p className='font-bold font-primary'>About Campaign</p>
                                <p className='font-primary text-blue text-[12px]' dangerouslySetInnerHTML={{ __html: description }}>
                                
                                </p>
                            </div>
                               
                        </div>
                    </div>
                    {
                    source == 'main' ?


                        <div className="mt-3 flex justify-between mx-1 lg:px-5">
                            <Link className='w-[150px] lg:w-[200px] bg-white border-blue rounded-[40px] flex justify-center items-center border-2 text-blue mt-[30px]' to={`/live/${_id}/${userId}`}>View Post</Link>
                            
                            <Button onclick={() => {
                                navigate(`/brand/challenge/result/${_id}`)
                            }} text='View Results' containerClass="w-[150px] lg:w-[200px] bg-blue text-yellow mt-[30px]" />
                        </div>
                        :
                        <></>
                }
                    {
                        challengeCode &&
                        <div onClick={() => clickToCopy("link")} style={{ backgroundColor: "rgb(248 250 252)" }} className='mt-2 rounded-[10px] p-3'>
                            <p className='font-primary text-[12px] flex gap-2 items-center cursor-[pointer] font-[600]'>
                                {`https://${window.location.host}/e/${slugify(brandName)}/${challengeCode}`}
                                <span className='cursor-[pointer]' >
                                    <FiCopy />
                                </span>
                            </p>
                        </div>
                    }
                </> : ''}
        </div>
    )
}

export default EventCard