import React, { useState, useEffect } from "react";

import "./custom.slider.css";

function CustomCarousel({ children } ) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [slideDone, setSlideDone] = useState(true);
  const [timeID, setTimeID] = useState(null);
  const [posts, setPosts] = useState([]);
  const [activeItem, setActiveItem] = useState([]);
  
  useEffect(() => {
    if (slideDone) {
      setSlideDone(false);
      setTimeID(
        setTimeout(() => {
          slideNext();
          setSlideDone(true);
        }, 5000)
      );
    }
  }, [slideDone]);

  useEffect(() => {
    setPosts(children)
  }, [children]);

  
  useEffect(() => {
    console.log(activeIndex)
    setActiveItem(children[activeIndex])
  }, [activeIndex]);


  const slideNext = () => {
    setActiveIndex((val) => {
      if (val >= posts.length - 1) {
        return 0;
      } else {
        return val + 1;
      }
    });
  };

  const slidePrev = () => {
    setActiveIndex((val) => {
      if (val <= 0) {
        return posts.length - 1;
      } else {
        return val - 1;
      }
    });
  };

  const AutoPlayStop = () => {
    if (timeID > 0) {
      clearTimeout(timeID);
      setSlideDone(false);
    }
  };

  const AutoPlayStart = () => {
    if (!slideDone) {
      setSlideDone(true);
    }
  };

  return (
    <div
      className="container__slider"
      onMouseEnter={AutoPlayStop}
      onMouseLeave={AutoPlayStart}
      style={{
        minHeight : "80vh"
      }}
    >
      {children.map((item, index) => {
        return (
          <div
            className={`slider__item slider__item-active-${activeIndex == index ? 'active' :''} `}
            
          >
            <div className="grid grid-cols-3 w-full">
            {
              item.map((it, i)=> (
                <>
                <img className="h-100 max-h-[70vh]" key={`${it._id}${i}`} alt="Image Alt" loading="lazy" src={it.media.mediaSecureUrl} />
                </>
              ))
            }
            </div>
          </div>
        );
      })}

      <div className="container__slider__links">
        {children.map((item, index) => {
          return (
            <button
              key={index}
              className={
                activeIndex === index
                  ? "container__slider__links-small container__slider__links-small-active"
                  : "container__slider__links-small"
              }
              onClick={(e) => {
                e.preventDefault();
                setActiveIndex(index);
              }}
            ></button>
          );
        })}
      </div>

      {/* <button
        className="slider__btn-next"
        onClick={(e) => {
          e.preventDefault();
          slideNext();
        }}
      >
        {">"}
      </button>
      <button
        className="slider__btn-prev"
        onClick={(e) => {
          e.preventDefault();
          slidePrev();
        }}
      >
        {"<"}
      </button> */}
    </div>
  );
}

export default CustomCarousel;
