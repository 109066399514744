import { useEffect, useState } from "react";
import { ActionType } from "../../store/actions";
import { useDispatch } from "react-redux";
import Button from "../../components/button";
import { Footer, Hr } from "../../components";
import { AppSection } from "../../components/home";
import { ToastContainer, toast } from "react-toastify";
import ClientUserForm from "../../components/clients/click_user_form";
import { useNavigate, useParams } from "react-router-dom";
import instance, { baseUrl } from "../../helpers/axios";
import { Logo, Logo1, Logo2 } from "../../components/images";
import Loader from "../../helpers/loader";
import Input from "../../components/input";
import { Swiper, SwiperSlide } from "swiper/react";
import CustomCarousel from "./customSlider";
import { useTypedSelector } from "../../hooks/useSelector";
import axios from "axios";
import { io } from "socket.io-client";
import { BiExitFullscreen, BiFullscreen } from "react-icons/bi";

export default function LiveClips() {
  const [showForm1, setShowForm1] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [userId, setUserId] = useState("");

  const [challenge, setChallenge] = useState<any>({});
  const [displays, setDisplay] = useState<any[]>([]);
  const [posts, setPosts] = useState<any[]>([]);
  const [newPost, setNewPost] = useState<any>(null);

  const brands: any = [
    "/elevate1.jpg",
    "/elevate3.jpg",
    "/elevate2.jpg",
    Logo1,
    Logo2,
    Logo2,
    Logo2,
    Logo2,
    Logo2,
    Logo2,
    Logo2,
    Logo2,
    Logo2,
    Logo2,
    Logo2,
    Logo2,
    Logo2,
  ];

  const { loggedInBrand } = useTypedSelector((state) => state.appReducer);

  const headers = {
    Authorization: `Bearer ${loggedInBrand}`,
  };

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  function groupArrayIntoChunks(array: any[], chunkSize: number) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  }

  const onMessage = (ev: any) => {
    if (ev.data) {
      setNewPost(ev.data);
    }
  };

  useEffect(() => {
    if (newPost) {
      let p: any[] = posts;
      console.log(posts.length);
      p = [...p, newPost];
      setPosts(p);
      console.log(p.length);
      console.log("wow after");
      let newGroup = groupArrayIntoChunks(p, 3);
      console.log(newGroup);
      setDisplay(newGroup);
      setNewPost(null);
    }
  }, [newPost]);

  useEffect(() => {
    dispatch({
      type: ActionType.UPDATE_LAYOUT,
      payload: "none",
    });
    let locator = window.location.href.includes("localhost")
      ? "beentos.herokuapp.com"
      : "beentos-brand-api-54b5b6f89390.herokuapp.com";
    const URL = window.location.href.includes("localhost")
      ? `ws://${locator}?userId=${params.brand}`
      : `wss://${locator}?userId=${params.brand}`;
    const socket = io(URL);

    function onConnect() {
      getRecords();
    }

    function onDisconnect() {}

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("new-post", onMessage);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("new-post", onMessage);
    };
  }, []);

  const getRecords = async () => {
    instance({
      method: "GET",
      url: `/challenge/join-socket-challenge/${params.challenge}`,
      headers: headers,
    })
      .then((response) => {
        console.log(response);
        fetchConstant();
        toast.success("Socket connected");
      })
      .catch((e) => {
        toast.error("Socket error, please reload the page");
      });
  };

  const fetchConstant = async () => {
    let url = `/challenge-post/all?challenge=${
      params.challenge
    }&_limit=${10000000000}&_orderBy=createdAt&_order=asc`;
    instance({
      method: "GET",
      url: url,
      headers: headers,
    })
      .then((response) => {
        if (displays.length == 0) {
          setDisplay(groupArrayIntoChunks(response.data.data.docs, 3));
          setPosts(response.data.data.docs);
        }
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    console.log("the url", URL);
    instance({
      method: "GET",
      url: `/challenge/all?_id=${params.challenge}`,
    })
      .then((response) => {
        var docs = response.data.data.docs[0];
        if (docs) {
          setChallenge(docs);
        }
      })
      .catch((e) => {});
  }, []);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullScreen(true)
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setIsFullScreen(false)
    }
  };

  return (
    <div className="bg-[#f8f9fa] h-[100vh] flex justify-center items-center w-full">
      <ToastContainer />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="">
          <div className="container">
            <h3
              style={{}}
              className={`text-blue text-[32px] text-center font-primarybold text-uppercase`}
            >
              {challenge.title}
            </h3>
            <div className="absolute p-2 right-[20px] bottom-[20px]" style={{
              background : "rgba(128, 128, 128, 0.4)",
              cursor : "pointer"
            }}>
            {
              isFullScreen
              ?
              <BiExitFullscreen onClick={()=>toggleFullScreen()} />
              :
              <BiFullscreen onClick={()=>toggleFullScreen()} />
            }
            </div>
          </div>
          <CustomCarousel children={displays} />
        </div>
      )}
    </div>
  );
}