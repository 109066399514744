import { useEffect, useState } from "react"
import { ActionType } from "../../store/actions";
import { useDispatch } from "react-redux";
import Button from "../../components/button";
import { Footer, Hr } from "../../components";
import { AppSection } from "../../components/home";
import { toast, ToastContainer } from 'react-toastify'
import ClientUserForm from "../../components/clients/click_user_form";
import { useNavigate, useParams } from "react-router-dom";
import instance from "../../helpers/axios";
import { Logo, successBall } from "../../components/images";
import Loader from "../../helpers/loader";
import Input from "../../components/input";
import { FiUpload } from "react-icons/fi";
import { capitalizeFirstLetter } from "../../helpers/helpers";

export default function ClientEvent() {
    const [showForm1, setShowForm1] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    
    const [showModal, setShowModal] = useState(false);

    const [challenge, setChallenge] = useState<any>({});
    const [guidelines, setGuidelines] = useState<any>([])
    const [uploadedFiles, setUploadedFiles] = useState<any>([])
    const [brand, setBrand] = useState<any>({});
    const [avatar, setAvatar] = useState(Logo);
    const [brandColor, setBrandColor] = useState("#0b0149");

    const [email, setEmail] = useState("")
    const [lastName, setLastName] = useState("")
    const [instagramID, setInstagramID] = useState("")
    const [content, setContent] = useState("")
    const [firstName, setFirstName] = useState("")
    const [fname, setFname] = useState("")
    const [participate, setParticipate] = useState("picture")
    const [phone, setPhone] = useState("")
    const [formError, setFormError] = useState("")
    const [contentError, setContentError] = useState("")
    const [formError1, setFormError1] = useState("")
    const [challengeId, setChallengeId] = useState(challenge || "")
    const [file, setFile] = useState<any>(null)

    const dispatch = useDispatch();
    const params = useParams()
    const navigate = useNavigate()
    useEffect(() => {
        dispatch({
            type: ActionType.UPDATE_LAYOUT,
            payload: "none"
        })

        if (!params.challengeCode) {

        }
        instance({
            method: "GET",
            url : `/challenge/all?challengeCode=${params.challengeCode}`
        }).then((response) => {
            const b = response.data.data.docs[0];
            if (response.data.data.totalDocs == 0) navigate("/not-found")
            setChallenge(response.data.data.docs[0])
            if (b.published != true) navigate("/not-found");
            setChallenge(b)
            setBrand(b.brand)
            instance({
                method: "GET",
                url: `file?userId=${b.brand.brandId}&fileIntent=${b._id}&_searchBy[]=fileId&_keyword=advert`
            }).then((response) => {
                setUploadedFiles(response.data.data.docs)
            })

            if (b.brand.avatar) {
                setAvatar(b.brand.avatar)
                if (b.brand.brandColor) setBrandColor(b.brand.brandColor)
            }
            var g = b.guidelines.split("~end-of-a-step~")
            setGuidelines(g)
            setIsLoading(false)
        }).catch((e) => {
            setIsLoading(false)

        })
    }, [])
    const fileUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormError("")
        setFile(null)
        // console.log(e.target.files )
        let file_ = e.target.files ? e.target.files[0] : null;
        if (file_) {
            // if (!file_.type.includes("video")) {
            // setFormError("Only video file is allowed")
            // } else {
            setFile(file_)
            // }
        } else {
            setFile(null)
        }
    }
    const space = {
        marginBottom: "30px"
    }

    const space1 = {
        marginBottom: "10px"
    }
    const closeModal = () => {
        setShowModal(false)
    }
    // challenge-post/external/create/multi/event
    const addMember = (stp = "upload") => {
        // return setStep("last")
        setFormError("")
        if (email == "" || firstName == "") return setFormError("All fields are required");
      
        let name = firstName.split(" ");
        if (!name[1]) {
            return setFormError("Invalid name. Your fullname should be (e.g Sam Oprah)");
        }
        setFname(name[0]);
        setLastName(name[1]);
        if (!file) {
            return setFormError("Please upload your image")
        }

        setLoading(true)
        const formdata = new FormData();
        formdata.append("email", email)
        formdata.append("firstName", name[0])
        formdata.append("lastName", name[1])
        formdata.append("challenge", challenge._id)
        if (participate == "video" || participate == "picture") formdata.append("file", file)
        formdata.append("onboardUser", "true")
        const contentType = participate.replace("picture", "image")
        
        formdata.append("contentType", contentType)
        if (participate == "text" || participate == "picture") formdata.append("contentText", content)

        instance({
            method: "POST",
            url: "/challenge-post/external/create/multi/event",
            data: formdata
        }).then((response) => {
            // console.log(response)
            setShowModal(true)
            toast.success("Your upload has been successfully submitted!");
            setFile("");
        }).catch((e) => {
            toast.error(e.response.data.message);
        }).finally(() => {
            setLoading(false)
        })
    }
    return (
        <div className="bg-[#f8f9fa]">
            <ToastContainer />
            {
                showModal 
                &&
            <div className='fixed top-0 left-0 w-full border-2 flex items-center justify-center z-[999] h-[100vh]'>
                <div className="w-full flex justify-center">

                    <div onClick={() => closeModal()} className='back_drop backdrop-blur fixed top-0 left-0 w-full bg-blue/30 h-[100vh] bg-blue'></div>
                    <div className='bg-white shadow-lg rounded-[15px]  w-[95%] lg:w-[670px]  relative'>
                        <div
                            className='w-full p-3 rounded-[15px] rainbow_background h-full'
                        >
                            <div className='max-w-[316px] text-center flex flex-col items-center mx-auto'>
                                <img src={successBall} className='h-[120px] object-contain' />
                                <div className="mb-3"></div>
                                <p
                                    style={{
                                        color: brandColor
                                    }} >
                                    <i>Thank you for your submission!</i> </p>
                                <div className="mb-3"></div>

                                <Button style={{
                                    borderColor: brandColor
                                }} onclick={() => closeModal()} containerClass=' rounded-[20x] min-w-[150px]' text='Close' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }


            {
                isLoading ?
                    <Loader />
                    :
                    <div style={showForm1 ? {
                        overflow: "hidden",
                        height: "80vh"
                    } : {}}>
                        <div className="container">
                            <div className="space-y-10 divide-y divide-gray-900/10" style={{
                                height: "fit-content",
                                minHeight: "100vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%"
                            }}>
                                <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-2">
                                    <div className="px-4 py-2">
                                        <div className="brandLogo flex justify-center my-[20px] text-center">
                                            <img style={{
                                                height: "120px"
                                            }} src={avatar} />
                                        </div>
                                        <div className="challengeTitle mb-[40px] mt-4">
                                            <h3 style={{
                                                color: brandColor
                                            }} className={`text-[32px] text-center font-primarybold`}>{challenge.title}</h3>
                                        </div>
                                        <div className="challengeDetails">
                                            {/* <p><strong>About Event</strong></p> */}
                                            <div style={space1}></div>

                                            <p style={{ fontWeight: 500, textAlign: "justify", listStyleType: "disk" }}
                                                dangerouslySetInnerHTML={{ __html: challenge.description }}>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl ">
                                        <div className="px-4 py-6 sm:p-8">
                                            <div className='w-full'>
                                                <label>Name *</label>
                                                <Input className='h-[20px] py-1' placeholder='E.g. Sam Oprah' value={firstName} setValue={setFirstName} />
                                            </div>
                                            <div className="my-3"></div>
                                            <div className='w-full'>
                                                <label>Email *</label>
                                                <Input type='email' placeholder='E.g. someone@example.com' value={email} setValue={setEmail} />
                                            </div>

                                            <div className="my-3"></div>

                                            <div className='border-2 border-[#0B01491F] rounded-[8px] w-full flex items-center py-1'>
                                                    <input accept={participate == "video" ? 'video/*' : 'image/*'} onChange={fileUploadChange} type="file" hidden name="uploadVideo" id="uploadFile" />
                                                    <label htmlFor='uploadFile' className='flex w-full h-[100px] justify-center items-center flex-col text-center'>
                                                        <FiUpload style={{ fontSize: "31px", color: participate == "video" ? "#7E799F" : "#000" }} />
                                                        {
                                                            file ? <>
                                                                <p
                                                                    style={{
                                                                        color: brandColor
                                                                    }} className='text-[15px] '>{capitalizeFirstLetter(participate)} uploaded, click to change the {participate}</p>
                                                                <p className='text-[15px] text-success'>{file?.name}</p>

                                                            </>
                                                                :
                                                                <>
                                                                    <p className='text-[15px] text-[#7E799F]'>Click to {participate == "picture" ? "Upload picture" : "Upload Video"}</p>
                                                                    {
                                                                        formError !== "" && <p className='text-[12px] text-red-700'>{formError}</p>
                                                                    }
                                                                    {
                                                                        file && <>
                                                                        </>
                                                                    }
                                                                </>
                                                        }

                                                    </label>
                                                </div>
                                            <div className="text-center mt-3">
                                                <Button
                                                    style={{
                                                        background: brandColor
                                                    }}  onclick={() => { addMember() }} loading={loading} text='Submit' containerClass='text-white border-0 w-[250px] lg:w-[fit-content] px-4 mb-3' h='40px' />
                                            </div>
                                            {
                                                formError !== "" && <p className='text-[12px] text-center text-red-700'>{formError}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            uploadedFiles.length > 0 &&

                            <div className="container">
                                {
                                    uploadedFiles.length == 2
                                        ?
                                        <div className="grid md:grid-cols-2 ">
                                            {
                                                uploadedFiles.map((item: any, i: number) => (
                                                    <div>
                                                        <a target="_blank" href={`${item.fileRedirectUrl ? (item.fileRedirectUrl.includes("http") ? item.fileRedirectUrl : "//" + item.fileRedirectUrl) : "#?"}`}>
                                                            <img src={item.secureUrl} alt="" />
                                                        </a>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        :

                                        <><div>
                                            <a target="_blank" href={`${uploadedFiles[0].fileRedirectUrl ? (uploadedFiles[0].fileRedirectUrl.includes("http") ? uploadedFiles[0].fileRedirectUrl : "//" + uploadedFiles[0].fileRedirectUrl) : "#?"}`}>
                                                <img src={uploadedFiles[0].secureUrl} alt="" />
                                            </a>
                                        </div>
                                            <div className="grid md:grid-cols-2 ">
                                                {
                                                    uploadedFiles.map((item: any, i: number) => (
                                                        <>
                                                            {
                                                                i > 0 &&
                                                                <div>
                                                                    <a target="_blank" href={`${item.fileRedirectUrl ? (item.fileRedirectUrl.includes("http") ? item.fileRedirectUrl : "//" + item.fileRedirectUrl) : "#?"}`}>
                                                                        <img src={item.secureUrl} alt="" />
                                                                    </a>
                                                                </div>
                                                            }
                                                        </>
                                                    ))
                                                }
                                            </div>
                                        </>
                                }

                            </div>
                        }
                        <br />
                        <Hr />
                        <div className="bg-whit">
                            <div className="mt-5">
                                <h3 className="text-[16px] text-center font-primarybold text-blue dark:text-white">Powered By BEENTOS:</h3>
                            </div>
                            <div className="text-center w-full flex justify-center mt-4">
                                {/* <img className="h-[95px]" src={Logo} /> */}
                            </div>
                            {/* <AppSection source="landing" /> */}
                            <Footer source="landing" />
                        </div>
                    </div>
            }
        </div>

    )
}