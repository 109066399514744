import React from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import {Footer, Navbar} from './components/index';
import appMode from './helpers/mode';
import ScrollToTop from './helpers/scrollToTop';
import {Home, Blog, Register, Terms, Privacy, BrandLogin, BrandLogout, BrandRegister, Notfound, BrandRegister2, BrandRegister3, BrandRegister4, BrandDashboard, BrandAccountSettings, BrandNotification, BrandPrivacy, BrandSecurity, BrandPayment, BrandPaymentMethod, OTPVerification, CreateChallenge, CreateChallenge2, CreateChallenge3, CreateChallenge4, CreateChallenge5, CreateChallenge6, CreateChallenge22, Clip, Result, BrandHelp, Analytics, CreateChallenge21, ForgotPassword, PostDetails, BrandRegister1, AdminDashboard, DraftChallenge, AdminManageUsers, AdminManageBrands, AdminManageContents, AdminSettings, AdminUserAuthorization, AdminCampaignManagement, AdminManageNotification, AdminPaymentManagement, AdminLogin, AdminSystemIntegration, AdminTransactionDetails, AdminTeamMembers, AdminCampaignManagementBeentos, AdminSubscriptions, AdminPackages, AdminPackageAdd} from './pages';
import { useTypedSelector } from './hooks/useSelector';
import { BrandNavbar } from './components/brand';
import BrandLayout from './layout/brandLayout';
import ResetPassword from './pages/auth/resetPassword';
import { AdminNavbar } from './components/admin';
import AdminLayout from './layout/adminLayout';
import MyDocument from './pages/brands/pdf';
import ReportTemplate1 from './pages/brands/pdf1';
import ExcelExport from './pages/brands/excel';
import AdminCreateChallenge from './pages/admin/challenge/create';
import AdminCreateChallenge3 from './pages/admin/challenge/create_3';
import AdminCreateChallenge2 from './pages/admin/challenge/create_2_1';
import AdminCreateChallenge5 from './pages/admin/challenge/create_5';
import AdminCreateChallenge6 from './pages/admin/challenge/create_4';
import PendingChallenges from './pages/brands/pending_challenges';
import GenerateInvoice from './pages/brands/generate_invoice';
import VerifyChallengePayment from './pages/brands/verify_challenge_payment';
import Tester from './pages/beta';
import SubscriptionPayments from './pages/admin/subscription_payments';
import TransactionChallenge from './pages/admin/transaction';
import TransactionDetailsAdmin from './pages/admin/transaction_details';
import PayoutManagement from './pages/admin/payouts';
import AdminReportedContent from './pages/admin/reported_content';
import AdminPinndPosts from './pages/admin/pinned-content';
import AdminLogout from './pages/admin/logout';
import BrandSubscribe from './pages/brands/settings/subscribe';
import AdminCoupons from './pages/admin/coupons';
import AdminAddCoupon from './pages/admin/coupon_add';
import Others from './pages/admin/others';
import AdminBlog from './pages/admin/admin_blogs';
import AdminBlogCreate from './pages/admin/admin_blog_create';
import AdminBlogView from './pages/admin/admin_blog_view';
import AdminFaqs from './pages/admin/admin_faqs';
import AdminFaqAdd from './pages/admin/admin_faqs_add';
import ClientLanding from './pages/clients/landing';
import ResetPasswordUser from './pages/auth/resetPasswordUser';
import VideoEditor from './pages/brands/editor/video_editor';
import BrandRegisterMain from './pages/brands/signup1';
import AddSubscriptionForBrand from './pages/admin/challenge/create_6';
import ThirdPartyApps from './pages/brands/settings/apps';
import AdminReportedContentTimeline from './pages/admin/reported_content_timeline';
import GetBeentos from './pages/getBeentos';
import UploadBanners from './pages/brands/challenge/upload_banners';
import VerifyPost from './pages/verify_post';
import ClientEvent from './pages/clients/event';
import Events from './pages/brands/events';
import CreateEvent from './pages/brands/challenge/create_event';
import CreateEvent2 from './pages/brands/challenge/create_events_2';
import LiveClips from './pages/clients/live';

function App(){
  appMode.initMode();
  const { layoutType } = useTypedSelector((state) => state.appReducer);
  return (
    <Router>

    <div className='dark:bg-black'>
      {
        layoutType === 'main' ? <Navbar /> : ''
      }
      {
        layoutType === 'brand' ? <BrandNavbar /> : ''
      }

      {
        layoutType === 'admin' ? <AdminNavbar /> : ''
      }
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pdf" element={<ReportTemplate1 />} />
        <Route path="/download" element={<GetBeentos />} />
        <Route path="/excel" element={<ExcelExport />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset/:userName" element={<ResetPasswordUser />} />
        
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/otp/:type" element={<OTPVerification />} />
        <Route path='beta-tester' element={<Tester />} />

        <Route path={"/blog"} >
          <Route path="" element={<Blog />} />
          <Route path=":id" element={<Blog />} />
        </Route>
        <Route path={"/company"} >
          <Route path='' element={<Register />} />
          <Route path='register' element={<Register />} />
        </Route>
        <Route path={'/verify-post'} element={<VerifyPost />} />
        <Route path='verify-challenge-payment/:id' element={<BrandLayout Element={ <VerifyChallengePayment /> }/>} />
        <Route path='verify-subscription-payment/:id' element={<BrandLayout Element={ <VerifyChallengePayment /> }/>} />

        <Route path={"/brand"} >
          <Route path='' element={<Register />} />
          <Route path='register' element={<BrandRegisterMain />} />
          <Route path='register-step-1' element={<BrandRegister1 />} />
          <Route path='register-step-2' element={<BrandRegister2 />} />
          <Route path='register-step-3' element={<BrandRegister3 />} />
          <Route path='register-step-4' element={<BrandRegister4 />} />
          <Route path='login' element={<BrandLogin />} />
          <Route path='logout' element={<BrandLogout />} />


          {/* dashboard */}
          <Route path='dashboard' 
          element={<BrandLayout Element={ <BrandDashboard /> } />} />
          <Route path='generate-invoice/:id' element={<BrandLayout Element={ <GenerateInvoice /> }/>} />
          <Route path='challenges/draft' element={<BrandLayout Element={ <DraftChallenge /> }/>} />
          <Route path='challenges/pending' element={<BrandLayout Element={ <PendingChallenges /> }/>} />
          <Route path='editor' element={<BrandLayout Element={ <VideoEditor /> }/>} />
          <Route path='analytics' element={<BrandLayout Element={ <Analytics /> } />} />
          <Route path='settings'>
            <Route path='' element={<BrandLayout Element={ <BrandAccountSettings /> } />} />
            <Route path='notification' element={<BrandLayout Element={ <BrandNotification /> } />} />
            <Route path='privacy' element={<BrandLayout Element={ <BrandPrivacy /> } />} />
            <Route path='security' element={<BrandLayout Element={ <BrandSecurity /> } />} />
            <Route path='help' element={<BrandLayout Element={ <BrandHelp /> } />} />
            <Route path='payment' element={<BrandLayout Element={ <BrandPayment /> } />} />
            <Route path='payment/methods' element={<BrandLayout Element={ <BrandPaymentMethod /> } />} />
            <Route path='apps' element={<BrandLayout Element={ <ThirdPartyApps /> } />} />
          </Route>
            <Route path='subscription/:page' element={<BrandLayout Element={ <CreateChallenge6 /> } />} />
            <Route path='events'>
              <Route path='' element={<BrandLayout Element={ <Events /> } />} />
              <Route path='create' element={<BrandLayout Element={ <CreateEvent /> } />} />
              <Route path='create-step-2' element={<BrandLayout Element={ <CreateEvent2 /> } />} />

        </Route>

          <Route path='challenge'>
              <Route path='' element={<BrandLayout Element={ <BrandDashboard /> } />} />
              <Route path='create' element={<BrandLayout Element={ <CreateChallenge /> } />} />
              <Route path='create-step-3' element={<BrandLayout Element={ <CreateChallenge21 /> } />} />
              <Route path='create-step-3-2' element={<BrandLayout Element={ <CreateChallenge22 /> } />} />
              <Route path='create-step-2' element={<BrandLayout Element={ <CreateChallenge3 /> } />} />
              <Route path='create-step-payment' element={<BrandLayout Element={ <CreateChallenge4 /> } />} />
              <Route path='create-step-check' element={<BrandLayout Element={ <CreateChallenge5 /> } />} />
              <Route path='create-step-6' element={<BrandLayout Element={ <CreateChallenge6 /> } />} />
              <Route path='update-banner/:challenge' element={<BrandLayout Element={ <UploadBanners /> } />} />
              <Route path='clips/:id' element={<BrandLayout Element={ <Clip /> } />} />
              <Route path='clips/winners/:id' element={<BrandLayout Element={ <Clip /> } />} />
              <Route path='result/:id' element={<BrandLayout Element={ <Result /> } />} />


              {/* edit routes */}
              <Route path='edit' element={<BrandLayout Element={ <CreateChallenge /> } />} />
              <Route path='edit-step-3' element={<BrandLayout Element={ <CreateChallenge21 /> } />} />
              <Route path='edit-step-3-2' element={<BrandLayout Element={ <CreateChallenge22 /> } />} />
              <Route path='edit-step-2' element={<BrandLayout Element={ <CreateChallenge3 /> } />} />
              <Route path='edit-step-payment' element={<BrandLayout Element={ <CreateChallenge4 /> } />} />
              <Route path='edit-step-check' element={<BrandLayout Element={ <CreateChallenge5 /> } />} />
              <Route path='edit-step-6' element={<BrandLayout Element={ <CreateChallenge6 /> } />} />
            </Route>
        </Route>


        {/* ADMIN ROUTES */}
        <Route path='/admin' >
          <Route path='' element={<AdminLayout Element={ <AdminDashboard /> } />} />
          <Route path='logout' element={<AdminLogout />} />
          <Route path='login' element={<AdminLogin />} />
          <Route path='create-challenge/:brandid/'>
            <Route path='' element={<AdminLayout Element={ <CreateChallenge /> } />} />
            <Route path='step-2' element={<AdminLayout Element={ <CreateChallenge3 /> } />} />
            <Route path='step-3' element={<AdminLayout Element={ <CreateChallenge21 /> } />} />
            <Route path='create-step-check' element={<AdminLayout Element={ <AdminCreateChallenge5 /> } />} />
            <Route path='create-step-payment' element={<AdminLayout Element={ <AdminCreateChallenge6 /> } />} />
          </Route>
          <Route path='edit-challenge/:brandid/'>
              <Route path='' element={<AdminLayout Element={ <CreateChallenge /> } />} />
              <Route path='edit-step-3' element={<AdminLayout Element={ <CreateChallenge21 /> } />} />
              <Route path='edit-step-3-2' element={<AdminLayout Element={ <CreateChallenge22 /> } />} />
              <Route path='edit-step-2' element={<AdminLayout Element={ <CreateChallenge3 /> } />} />
              <Route path='edit-step-payment' element={<AdminLayout Element={ <CreateChallenge4 /> } />} />
              <Route path='edit-step-check' element={<AdminLayout Element={ <CreateChallenge5 /> } />} />
              <Route path='edit-step-6' element={<AdminLayout Element={ <CreateChallenge6 /> } />} />
            </Route>

          <Route path='blog'>
            <Route path='' element={<AdminLayout Element={ <AdminBlog /> } />} />
            <Route path=':id' element={<AdminLayout Element={ <AdminBlogView /> } />} />
            <Route path='post' element={<AdminLayout Element={ <AdminBlogCreate /> } />} />
            <Route path='update/:id' element={<AdminLayout Element={ <AdminBlogCreate /> } />} />
          </Route>

          <Route path='faqs'>
            <Route path='' element={<AdminLayout Element={ <AdminFaqs /> } />} />
            <Route path='add' element={<AdminLayout Element={ <AdminFaqAdd /> } />} />
          </Route>

          <Route path='coupons' element={<AdminLayout Element={ <AdminCoupons /> } />} />
          <Route path='others' element={<AdminLayout Element={ <Others /> } />} />
          <Route path='coupons/add' element={<AdminLayout Element={ <AdminAddCoupon /> } />} />
          <Route path='coupon/:id' element={<AdminLayout Element={ <AdminAddCoupon /> } />} />
          <Route path='manage-users' element={<AdminLayout Element={ <AdminManageUsers /> } />} />
          <Route path='users/team-members' element={<AdminLayout Element={ <AdminTeamMembers /> } />} />
          <Route path='manage-brands' element={<AdminLayout Element={ <AdminManageBrands /> } />} />
          <Route path='manage-brands/subscriptions/:brandid' element={<AdminLayout Element={ <AddSubscriptionForBrand /> } />} />
          <Route path='manage-contents' element={<AdminLayout Element={ <AdminManageContents /> } />} />
          <Route path='manage-contents/pinned' element={<AdminLayout Element={ <AdminPinndPosts /> } />} />
          <Route path='campaign-management' element={<AdminLayout Element={ <AdminCampaignManagement /> } />} />
          <Route path='campaign-management/view/:id' element={<AdminLayout Element={ <Clip /> } />} />
          <Route path='campaign-management/view/winners/:id' element={<AdminLayout Element={ <Clip /> } />} />
          <Route path='campaign-management/result/:id' element={<AdminLayout Element={ <Result /> } />} />
          <Route path='campaign-management-beentos' element={<AdminLayout Element={ <AdminCampaignManagementBeentos /> } />} />
          <Route path='manage-notification' element={<AdminLayout Element={ <AdminManageNotification /> } />} />
          <Route path='reported-contents' element={<AdminLayout Element={ <AdminReportedContent /> } />} />
          <Route path='reported-contents/timeline' element={<AdminLayout Element={ <AdminReportedContentTimeline /> } />} />
          <Route path='payment-management' element={<AdminLayout Element={ <AdminPaymentManagement /> } />} />
          <Route path='payout-management' element={<AdminLayout Element={ <PayoutManagement /> } />} />
          <Route path='payment-management/subscriptions' element={<AdminLayout Element={ <SubscriptionPayments /> } />} />
          <Route path='challenge-transaction/:id' element={<AdminLayout Element={ <TransactionDetailsAdmin /> } />} />
          <Route path='settings' element={<AdminLayout Element={ <AdminSettings /> } />} />
          <Route path='subscriptions' element={<AdminLayout Element={ <AdminSubscriptions /> } />} />
          <Route path='subscription/packages' element={<AdminLayout Element={ <AdminPackages /> } />} />
          <Route path='subscription/package/add' element={<AdminLayout Element={ <AdminPackageAdd /> } />} />
          <Route path='subscription/package/:package_id' element={<AdminLayout Element={ <AdminPackageAdd /> } />} />
          <Route path='system-integration' element={<AdminLayout Element={ <AdminSystemIntegration /> } />} />
          <Route path='transaction-details' element={<AdminLayout Element={ <AdminTransactionDetails /> } />} />
          <Route path='settings/user-authorization' element={<AdminLayout Element={ <AdminUserAuthorization /> } />} />
        </Route>

        <Route path={'/:brand/:challengeCode'} element={<ClientLanding />} />
        <Route path={'e/:brand/:challengeCode'} element={<ClientEvent />} />
        <Route path={'live/:challenge/:brand'} element={<LiveClips />} />

        <Route path='*' element={<Notfound />}></Route>
      </Routes>
      {
        layoutType === 'main' ? <Footer /> : ''
      }
    </div>
    </Router>
  );
}

export default App;
